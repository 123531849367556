import * as React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"
import styled from "styled-components"

const CostumeContainer = styled(Link)`
  display: flex;
  overflow: hidden;
  width: 400px;
  flex-direction: column;
  margin: 20px;
  background: #eee;
  transition: transform 0.2s;
  text-decoration: none;
  &:hover {
    transform: scale(1.1);
  }
`

const Img = styled(GatsbyImage)`
  height: 300px;
`

const CostumeTextContainer = styled.div`
  padding: 20px;
  padding-bottom: 40px;
  color: #666;
`

const Costume = ({ slug, image, alt, title, to }) => (
  <CostumeContainer to={slug}>
    <Img image={image} alt={alt} />
    <CostumeTextContainer>
      <h2 style={{ textDecoration: "none" }}>{title}</h2>
    </CostumeTextContainer>
  </CostumeContainer>
)

Costume.propTypes = {
  slug: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  alt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
}

export default Costume
