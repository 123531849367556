import * as React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"
import { getImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Costume from "../components/costume"

const CostumesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 20px;
`

export default function CostumesPage({ data }) {
  return (
    <Layout header="Costumes">
      <SEO
        title="Costumes"
        description="All of the Costumes finished by Detail Designs"
        pathname="/costumes"
      />
      <CostumesContainer>
        {data.allMarkdownRemark.edges.map(edge => {
          const image = getImage(edge.node.frontmatter.mainImage)
          return (
            <Costume
              key={edge.node.frontmatter.name}
              slug={edge.node.frontmatter.slug}
              image={image}
              alt={edge.node.frontmatter.name}
              title={edge.node.frontmatter.name}
              to={edge.node.frontmatter.slug}
            />
          )
        })}
      </CostumesContainer>
    </Layout>
  )
}

CostumesPage.propTypes = {
  data: PropTypes.object,
}

export const pageQuery = graphql`
  query CostumePageQuery {
    allMarkdownRemark(filter: { frontmatter: { type: { eq: "costume" } } }) {
      edges {
        node {
          frontmatter {
            name
            slug
            mainImage {
              childImageSharp {
                gatsbyImageData(
                  height: 300
                  placeholder: BLURRED
                  layout: CONSTRAINED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
      }
    }
  }
`
